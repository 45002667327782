<template>
  <div>
    <app-loading v-show="loadingData" />
    <v-container
      fill-height
      fluid
      grid-list-xl
    >
      <v-layout
        v-if="!loadingData"
        justify-center
        wrap
      >
        <v-col cols="12">
          <v-card
            color="#385F73"
            dark
          >
            <v-card-title class="headline">
              {{ partner.state === 'solicited' ? $vuetify.lang.t('$vuetify.partners.solicit') : $vuetify.lang.t('$vuetify.partners.activate') }}
            </v-card-title>
            <v-card-text>
              <v-card-subtitle>
                <p>{{ $vuetify.lang.t('$vuetify.partners.activate_description') }}</p>
                <p class="text-justify" v-if="partner.state === 'solicited'">
                  {{ $vuetify.lang.t('$vuetify.partners.solicit_description') }}
                </p>
                <p class="text-justify" v-if="partner.state === 'approved'">
                  {{ $vuetify.lang.t('$vuetify.partners.activate_activation') }}
                </p>
              </v-card-subtitle>
            </v-card-text>
            <v-card-actions>
              <v-btn
                v-if="partner.state === 'approved' && (partner.gateway === 'stripe' || JSON.parse(partner.gateway) === 'stripe')"
                @click="createAccount('standard')"
              >
                {{ $vuetify.lang.t('$vuetify.partners.existing_account') }}
              </v-btn>
              <a
                v-if="partner.state === 'approved' && (partner.gateway === 'paypal' || JSON.parse(partner.gateway) === 'paypal')"
                type="button" class="v-btn v-btn--is-elevated v-btn--has-bg theme--dark v-size--default"
                target="_blank" data-paypal-onboard-complete="onboardedCallback"
                data-paypal-button="true" id="customjs" >
                {{ $vuetify.lang.t('$vuetify.partners.existing_account_paypal') }}
              </a>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col
          v-if="!partner.id"
          col="12"
          md="12"
        >
          <partner-info />
        </v-col>
        <v-col v-if="partner.state === 'solicited' && userData.partner">
          {{ $vuetify.lang.t('$vuetify.partners.wait_result') }}
        </v-col>
        <v-flex
          v-if="partner.state === 'accepted' && userData.partner"
          md6
          xs12
          style="margin-top: 15px"
        >
          <material-card class="v-card-profile">
            <v-list
              class="pa-0"
              two-line
            >
              <v-list-item href="#">
                <v-list-item-action>
                  <v-icon color="indigo">
                    mdi-city
                  </v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title
                    v-text="getCompanyName"
                  />
                  <v-list-item-subtitle
                    v-text="
                      $vuetify.lang.t(
                        '$vuetify.profile.company'
                      )
                    "
                  />
                </v-list-item-content>
              </v-list-item>
              <v-divider inset />
              <v-list-item href="#">
                <v-list-item-action>
                  <v-icon color="indigo">
                    mdi-account
                  </v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title v-text="getFullName" />
                  <v-list-item-subtitle
                    v-text="
                      $vuetify.lang.t(
                        '$vuetify.firstName'
                      )
                    "
                  />
                </v-list-item-content>
              </v-list-item>
              <v-divider inset />
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-list-item
                    v-clipboard:copy="getReferralLink"
                    v-clipboard:success="onCopy"
                    v-clipboard:error="onError"
                    v-bind="attrs"
                    href="#"
                    v-on="on"
                  >
                    <v-list-item-action>
                      <v-icon color="indigo">
                        mdi-link
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="getReferralLink"
                      />
                      <v-list-item-subtitle
                        v-text="
                          $vuetify.lang.t('$vuetify.referralLink')
                        "
                      />
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <span>{{
                  $vuetify.lang.t(
                    '$vuetify.tips.copy_link'
                  )
                }}</span>
              </v-tooltip>
            </v-list>
          </material-card>
        </v-flex>
        <v-flex
          v-if="partner.state === 'accepted' && userData.partner"
          md6
          xs12
        >
          <linear-statistic
          class="my-4"
          :title="$vuetify.lang.t('$vuetify.partners.referrals')"
          :sub-title="
                  $vuetify.lang.t('$vuetify.partners.referralsSub')
                "
          :quantity="userData.referrals.length > 0 ? userData.referrals.length.toString() : '0'"
          icon="mdi-trending-up"
          color="success"
          :value="100"
        />
        </v-flex>
        <v-flex
          v-if="partner.state === 'accepted' && userData.partner"
          md12
          xs12>
          <v-card>
            <v-card-title>
              {{ $vuetify.lang.t(
              '$vuetify.partners.referralsList'
            ) }}
              <v-spacer />
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                :label="$vuetify.lang.t('$vuetify.actions.search')"
                single-line
                hide-details
              />
            </v-card-title>
            <v-data-table
              :headers="columnHeaders"
              :items="userData.referrals"
              :search="search"
            >
              <template v-slot:[`item.period`]="{ item }">
                {{ $vuetify.lang.t('$vuetify.plan.' + item.period) }}
              </template>
              <template v-slot:[`item.is_test`]="{ item }">
                {{ item.is_test ?  $vuetify.lang.t('$vuetify.actions.yes') : $vuetify.lang.t('$vuetify.actions.no') }}
              </template>
              <template v-slot:[`item.price`]="{ item }">
                {{ item.price + ' ' + item.currency }}
              </template>
              <template v-slot:[`item.percent`]="{ item }">
                {{ item.percent + '%' }}
              </template>
            </v-data-table>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>
<script>
import PartnerInfo from '../PartnerInfo'
import { mapState, mapActions, mapGetters } from 'vuex'
import partner from '../../../api/partner'
export default {
  components: { PartnerInfo },
  data () {
    return {
      loadingData: false,
      affiliateOn: false,
      search: ''
    }
  },
  computed: {
    ...mapState('auth', ['userData', 'pending', 'sessionData', 'partner']),
    ...mapState('pay', ['paypalData']),
    getFullName () {
      return `${this.userData.first_name} ${this.userData.last_name || ''}`
    },
    getCompanyName () {
      return `${this.userData.company.name}`
    },
    getReferralLink () {
      return `${process.env.VUE_APP_URL_AFFILIATE + this.partner.affiliate_id}`
    },
    columnHeaders () {
      return [
        {
          text: this.$vuetify.lang.t('$vuetify.firstName'),
          align: 'left',
          value: 'name'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.company'),
          value: 'company'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.country'),
          value: 'country'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.plan.name'),
          value: 'plan'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.plan.period'),
          value: 'period'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.plan.trial_period'),
          value: 'is_test'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.price'),
          value: 'price'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.plan.partner_percent'),
          value: 'percent'
        }
      ]
    }
  },
  async beforeCreate () {
    const localUrl = window.location.href.toString()
    if (localUrl.includes('&')) {
      const partnerId = localUrl.split('&')[0].split('?')[1].split('=')[1]
      const merchantId = localUrl.split('merchantIdInPayPal')[1].split('&')[0].replace('=', '')
      await partner.syncPayPalAccount({ partner: partnerId, merchantId: merchantId })
        .then(() => {
          window.location.href = window.location.pathname
        })
    } else if (localUrl.includes('stripeAccount')) {
      const partnerId = localUrl.split('&')[0].split('?')[1].split('=')[1]
      const merchantId = localUrl.split('stripeAccount')[1].replace('=', '')
      await partner.syncPayPalAccount({ partner: partnerId, stripe_account_id: merchantId })
        .then(() => {
          window.location.href = window.location.pathname
        })
    }
  },
  async created () {
    this.loadingData = true
    await this.getUserData().then(() => {
      this.loadingData = false
    })
    await this.getPayPalData()
    if (this.partner.state === 'approved' && (JSON.parse(this.partner.gateway) === 'paypal' || this.partner.gateway === 'paypal')) {
      const script = document.createElement('script')
      script.src = 'https://www.sandbox.paypal.com/webapps/merchantboarding/js/lib/lightbox/partner.js'
      document.body.appendChild(script)
      script.addEventListener('load', this.loadPayPalData)
    }
  },
  methods: {
    ...mapActions('auth', ['getUserData', 'callNewAccount']),
    ...mapActions('pay', ['getPayPalData']),
    loadPayPalData () {
      const self = this
      const getPaypalAuthToken = () => {
        return new Promise(function (resolve, reject) {
          const url = 'https://api-m.sandbox.paypal.com/v1/oauth2/token'

          const xhr = new XMLHttpRequest()
          xhr.open('POST', url)

          xhr.setRequestHeader('Accept', 'application/json')
          xhr.setRequestHeader('Accept-Language', 'en_US')
          xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded')
          xhr.setRequestHeader('Authorization', 'Basic ' + self.paypalData.auth)

          xhr.onreadystatechange = function () {
            if (xhr.readyState === 4) {
              resolve(xhr.responseText)
            }
          }

          const data = 'grant_type=client_credentials'

          xhr.send(data)
        // return xhr.responseText.access_token;
        })
      }
      const paypalAuthButton = (token) => {
        return new Promise(function (resolve, reject) {
          const url = 'https://api-m.sandbox.paypal.com/v2/customer/partner-referrals'

          const xhr = new XMLHttpRequest()
          xhr.open('POST', url)

          xhr.setRequestHeader('Content-Type', 'application/json')
          xhr.setRequestHeader('Authorization', `Bearer ${token}`)

          xhr.onreadystatechange = function () {
            if (xhr.readyState === 4) {
              resolve(xhr.responseText)
            }
          }

          const trackingId = '-' + new Date().toISOString() + '-SSSSSS'
          const paypalReturnUrl = window.location.href + '?partner=' + self.partner.id + '&'

          const data = `{
            "tracking_id": "${trackingId}",
            "operations": [
            {
                "operation": "API_INTEGRATION",
                "api_integration_preference": {
                "rest_api_integration": {
                    "integration_method": "PAYPAL",
                    "integration_type": "THIRD_PARTY",
                    "third_party_details": {
                    "features": [
                        "PAYMENT",
                        "REFUND"
                        ]
                    }
                }
                }
            }
            ],
            "products": [
            "EXPRESS_CHECKOUT"
            ],
            "legal_consents": [
            {
                "type": "SHARE_DATA_CONSENT",
                "granted": true
            }
            ],
            "partner_config_override": {
                "return_url": "${paypalReturnUrl}"
            }
        }`

          xhr.send(data)
        })
      }
      const getPaypalButton = async () => {
        const token = getPaypalAuthToken()
        token.then(data => {
          const accessToken = JSON.parse(data).access_token
          const paypalButton = paypalAuthButton(accessToken)
          paypalButton.then(value => {
            const data = JSON.parse(value)
            const actionUrl = data.links.filter((url) => url.rel === 'action_url')[0].href
            document.getElementById('customjs').setAttribute('href', actionUrl + '&displayMode=minibrowser')
            // setPaypalUrl(actionUrl)
          })
        })
      }
      getPaypalButton()
    },
    createAccount (type) {
      this.callNewAccount({ type: type, origin: window.location.pathname })
        .then(() => {
          if (this.sessionData.url) {
            window.location = this.sessionData.url + '?partner=' + this.partner.id + '&stripeAccount=' + this.sessionData.account
          }
        })
    },
    onCopy: function (e) {
      this.$Toast.fire({
        icon: 'success',
        title: this.$vuetify.lang.t(
		  '$vuetify.partners.confirm_copied'
        ) + e.text
	  })
    },
    onError: function (e) {
      this.$Toast.fire({
        icon: 'error',
        title: this.$vuetify.lang.t(
		  '$vuetify.partners.error_copied'
        )
	  })
    }
  }
}
</script>
<style  scoped>
</style>
